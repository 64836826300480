import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import format from 'date-fns/format';
import { licence } from '../../../config/firebase';

const mapState = state => ({
	config: state.firestore.ordered.config
});

class LicenceInfo extends Component {
	async componentDidMount() {
		const { firestore } = this.props;
		await firestore.setListener(`config/${licence}`);
	}

	async componentWillUnmount() {
		const { firestore } = this.props;
		await firestore.unsetListener(`config/${licence}`);
	}
	render() {
		const { config } = this.props;
		return (
			<div className="licence-details">
				{config && (
					<div>
						<p>
							<span className="left">Licenced to:</span>{' '}
							<span className="right">{config[0].owner}</span>
						</p>
						<p>
							<span className="left">Production:</span>{' '}
							<span className="right">{config[0].production}</span>
						</p>
						<p>
							<span className="left">Season:</span>{' '}
							<span className="right">{config[0].season}</span>
						</p>
						<p>
							<span className="left">Start Date:</span>{' '}
							<span className="right">
								{config[0].dateStart &&
									format(config[0].dateStart.toDate(), 'DD MMM YYYY')}
							</span>
						</p>
						<p>
							<span className="left">End Date:</span>{' '}
							<span className="right">
								{config[0].dateEnd &&
									format(config[0].dateEnd.toDate(), 'DD MMM YYYY')}
							</span>
						</p>
					</div>
				)}
			</div>
		);
	}
}
export default compose(
	connect(mapState),
	withFirestore
)(LicenceInfo);
