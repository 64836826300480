import {
	asyncActionStart,
	asyncActionFinish,
	asyncActionError
} from '../../app/async/asyncActions';
import { SubmissionError, reset } from 'redux-form';
import { toastr } from 'react-redux-toastr';

export const login = (credentials) => {
	return async (dispatch, getState, { getFirebase }) => {
		dispatch(asyncActionStart());
		const firebase = getFirebase();
		try {
			await firebase
				.auth()
				.signInWithEmailAndPassword(credentials.email, credentials.password);
			dispatch(asyncActionFinish());
		} catch (error) {
			dispatch(asyncActionError());
			console.log(error);
			throw new SubmissionError({
				_error: error.message
			});
		}
	};
};

export const updatePassword = (creds) => async (
	dispatch,
	getState,
	{ getFirebase }
) => {
	const firebase = getFirebase();
	const user = firebase.auth().currentUser;
	try {
		await user.updatePassword(creds.newPassword1);
		await dispatch(reset('account'));
		toastr.success('Success', 'Your password has been updated');
	} catch (error) {
		throw new SubmissionError({
			_error: error.message
		});
	}
};

export const resetPassword = (data) => async (
	dispatch,
	getState,
	{ getFirebase }
) => {
	const firebase = getFirebase();
	const auth = firebase.auth();
	try {
		await auth.sendPasswordResetEmail(data.email);
		await dispatch(reset('loginForm'));
		// toastr.success('Success', 'Check your email inbox for rest instructions.');
	} catch (error) {
		throw new SubmissionError({
			_error: error.message
		});
	}
};
