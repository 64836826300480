import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withFirestore } from 'react-redux-firebase';
import { Icon } from 'semantic-ui-react';

import './SideDrawer.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import packageJson from '../../../../../package.json';
import { licence } from '../../../config/firebase';
import LicenceInfo from '../../ui/LicenceInfo';

const mapState = (state, ownProps) => {
	let isAdmin = false;
	if (
		state.firebase.auth.isLoaded &&
		!state.firebase.auth.isEmpty &&
		state.firebase.profile.isAdmin
	) {
		isAdmin = true;
	}

	const isBadgePage = ownProps.match.params[0].includes('badge');

	return {
		isAdmin,
		isBadgePage,
		auth: state.firebase.auth,
		profile: state.firebase.profile,
		config: state.firestore.ordered.config
	};
};

class SideDrawer extends Component {
	state = {
		showMobileMenu: false
	};

	async componentDidMount() {
		const { firestore } = this.props;
		await firestore.setListener(`config/${licence}`);
	}

	async componentWillUnmount() {
		const { firestore } = this.props;
		await firestore.unsetListener(`config/${licence}`);
	}

	mobileMenuToggleHandler = () => {
		this.setState(prevState => {
			return { showMobileMenu: !prevState.showMobileMenu };
		});
	};
	render() {
		const { isAdmin, isBadgePage, profile, config } = this.props;

		if (isBadgePage) return <p />;

		return (
			<div className="side-drawer">
				<div className="side-drawer-header">
					<div className="logo">
						<Link to="/">
							{config && <img src={config[0].logoUrl} alt="CLIENT LOGO?" />}
						</Link>
					</div>
					<div
						className="mobile-nav-trigger"
						onClick={this.mobileMenuToggleHandler}
					>
						<Icon name="bars" />
					</div>
				</div>
				<NavigationItems
					isAdmin={isAdmin}
					profile={profile}
					visible={this.state.showMobileMenu}
					toggle={this.mobileMenuToggleHandler}
				/>
				<LicenceInfo />
				<div className="product-of">
					<h3>Powered By</h3>
					<a
						href="https://crewtracker.co.za"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/assets/images/crew-tracker-logo-sidebar.svg"
							alt="CrewTracker"
						/>
					</a>
				</div>
				<div className="version">Version {packageJson.version}</div>
			</div>
		);
	}
}

export default compose(
	withRouter,
	withFirestore,
	connect(mapState)
)(SideDrawer);
