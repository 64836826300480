// FontAwesome Library
import { library } from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/pro-regular-svg-icons';
import {
	faSignInAlt,
	faSignOutAlt,
	faClock,
	faFileUser,
	faTachometer,
	faClipboard,
	faUserCircle,
	faLifeRing,
	faUserEdit,
	faBuilding,
	faAddressCard,
	faSitemap,
	faSync,
	faCog
} from '@fortawesome/pro-solid-svg-icons';
import {
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faInfoCircle,
	faHistory,
	faCalendarAlt,
	faCalendarCheck
} from '@fortawesome/pro-light-svg-icons';
library.add(
	faSignInAlt,
	faSignOutAlt,
	faClock,
	faFileUser,
	faTachometer,
	faClipboard,
	faUserCircle,
	faLifeRing,
	faUserEdit,
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faInfoCircle,
	faHistory,
	faBuilding,
	faAddressCard,
	faSitemap,
	faSync,
	faCalendarAlt,
	faCalendarCheck,
	faCog
);
export {
	faSignInAlt,
	faSignOutAlt,
	faClock,
	faFileUser,
	faTachometer,
	faClipboard,
	faUserCircle,
	faLifeRing,
	faUserEdit,
	faChevronDown,
	faScanner,
	faBarcodeRead,
	faInfoCircle,
	faHistory,
	faBuilding,
	faAddressCard,
	faSitemap,
	faSync,
	faCalendarAlt,
	faCalendarCheck,
	faCog
};
