import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// ----------------------------------------------------------------------------

// PRODUCTION PROJECT
export const firebaseEnv = 'prod';
export const licence = '1AVseZak8cH5DvgH8FKv';
const firebaseConfig = {
	apiKey: 'AIzaSyA3wCRBYgN9MpVbNI_YG6G5rVcLi3VpICs',
	appName: 'Crew Tracker',
	authDomain: 'crew-tracker-production.firebaseapp.com',
	databaseURL: 'https://crew-tracker-production.firebaseio.com',
	projectId: 'crew-tracker-production',
	storageBucket: 'crew-tracker-production.appspot.com',
	messagingSenderId: '983258812861'
};

// // STAGING PROJECT
// export const firebaseEnv = 'staging';
// const firebaseConfig = {
// 	apiKey: '',
// 	appName: '',
// 	authDomain: '',
// 	databaseURL: '',
// 	projectId: '',
// 	storageBucket: '',
// 	messagingSenderId: ''
// };

// ----------------------------------------------------------------------------
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const settings = {
	timestampsInSnapshots: true
};
firestore.settings(settings);

export default firebase;
