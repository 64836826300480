import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import SideDrawer from '../../app/layout/navigation/SideDrawer/SideDrawer';
import {
	UserIsAuthenticated,
	VisibleOnlyAdmin
} from '../../features/auth/authWrapper';
import LoadingComponent from './LoadingComponent';
import LoginPage from '../../features/auth/login';
import Footer from './ui/footer';

const AsyncDashboard = Loadable({
	loader: () => import('../../features/dashboard'),
	loading: LoadingComponent
});
const AsyncHelp = Loadable({
	loader: () => import('../../features/help'),
	loading: LoadingComponent
});
const AsyncPrintEmployeeBadge = Loadable({
	loader: () => import('../../features/employees/PrintEmployeeBadge'),
	loading: LoadingComponent
});
const AsyncReports = Loadable({
	loader: () => import('../../features/reports'),
	loading: LoadingComponent
});
const AsyncUsers = Loadable({
	loader: () => import('../../features/users'),
	loading: LoadingComponent
});
const AsyncSettings = Loadable({
	loader: () => import('../../features/settings'),
	loading: LoadingComponent
});

const AsyncUserDetailedPage = Loadable({
	loader: () =>
		import('../../features/users/UserDetailedPage/UserDetailedPage'),
	loading: LoadingComponent
});
const AsyncCreateUser = Loadable({
	loader: () => import('../../features/users/CreateUser/CreateUser'),
	loading: LoadingComponent
});

const AsyncCompanies = Loadable({
	loader: () => import('../../features/companies'),
	loading: LoadingComponent
});
const AsyncCreateCompany = Loadable({
	loader: () => import('../../features/companies/CreateCompany/CreateCompany'),
	loading: LoadingComponent
});
const AsyncCompanyDetailedPage = Loadable({
	loader: () =>
		import('../../features/companies/CompanyDetailedPage/CompanyDetailedPage'),
	loading: LoadingComponent
});

const AsyncDepartments = Loadable({
	loader: () => import('../../features/departments'),
	loading: LoadingComponent
});
const AsyncCreateDepartment = Loadable({
	loader: () => import('../../features/departments/CreateDepartment'),
	loading: LoadingComponent
});
const AsyncDepartmentsDetailedPage = Loadable({
	loader: () => import('../../features/departments/DepartmentDetailedPage'),
	loading: LoadingComponent
});

const AsyncEmployees = Loadable({
	loader: () => import('../../features/employees'),
	loading: LoadingComponent
});
const AsyncCreateEmployee = Loadable({
	loader: () => import('../../features/employees/CreateEmployee'),
	loading: LoadingComponent
});
const AsyncEmployeesDetailedPage = Loadable({
	loader: () => import('../../features/employees/EmployeeDetailedPage'),
	loading: LoadingComponent
});

const AsyncClockInPage = Loadable({
	loader: () => import('../../features/clock/ClockIn'),
	loading: LoadingComponent
});
const AsyncClockOutPage = Loadable({
	loader: () => import('../../features/clock/ClockOut'),
	loading: LoadingComponent
});
const AsyncClockBatchPage = Loadable({
	loader: () => import('../../features/clock/Batch'),
	loading: LoadingComponent
});
const AsyncProductionSettingsPage = Loadable({
	loader: () => import('../../features/production'),
	loading: LoadingComponent
});

class App extends Component {
	render() {
		return (
			<div className="crew-finder">
				<Switch>
					<Route exact path="/" component={LoginPage} />
				</Switch>

				<Route
					path="/(.+)"
					render={() => (
						<div className="app-container">
							<SideDrawer />
							<div className="page-content">
								<Switch>
									<Route path="/" exact component={LoginPage} />
									<Route
										path="/dashboard"
										component={UserIsAuthenticated(AsyncDashboard)}
									/>

									<Route
										path="/reports"
										component={UserIsAuthenticated(AsyncReports)}
									/>
									<Route
										path="/help"
										exact
										component={UserIsAuthenticated(AsyncHelp)}
									/>
									<Route
										path="/settings"
										component={UserIsAuthenticated(AsyncSettings)}
									/>
									<Route
										path="/users/create"
										exact
										component={VisibleOnlyAdmin(AsyncCreateUser)}
									/>
									<Route
										path="/users/:id"
										component={VisibleOnlyAdmin(AsyncUserDetailedPage)}
									/>
									<Route
										path="/users"
										exact
										component={VisibleOnlyAdmin(AsyncUsers)}
									/>
									<Route
										path="/companies/create"
										exact
										component={VisibleOnlyAdmin(AsyncCreateCompany)}
									/>
									<Route
										path="/companies/:id"
										component={VisibleOnlyAdmin(AsyncCompanyDetailedPage)}
									/>
									<Route
										path="/companies"
										exact
										component={VisibleOnlyAdmin(AsyncCompanies)}
									/>
									<Route
										path="/employees/create"
										exact
										component={VisibleOnlyAdmin(AsyncCreateEmployee)}
									/>
									<Route
										path="/employees/:id"
										exact
										component={VisibleOnlyAdmin(AsyncEmployeesDetailedPage)}
									/>
									<Route
										path="/employees/:id/badge"
										exact
										component={VisibleOnlyAdmin(AsyncPrintEmployeeBadge)}
									/>
									<Route
										path="/employees"
										exact
										component={UserIsAuthenticated(AsyncEmployees)}
									/>
									<Route
										path="/departments/create"
										exact
										component={VisibleOnlyAdmin(AsyncCreateDepartment)}
									/>
									<Route
										path="/departments/:id"
										component={VisibleOnlyAdmin(AsyncDepartmentsDetailedPage)}
									/>
									<Route
										path="/departments"
										exact
										component={UserIsAuthenticated(AsyncDepartments)}
									/>
									<Route
										path="/clock-in"
										exact
										component={UserIsAuthenticated(AsyncClockInPage)}
									/>
									<Route
										path="/clock-out"
										exact
										component={UserIsAuthenticated(AsyncClockOutPage)}
									/>
									<Route
										path="/batch-clock"
										exact
										component={UserIsAuthenticated(AsyncClockBatchPage)}
									/>
									<Route
										path="/production-settings"
										exact
										component={VisibleOnlyAdmin(AsyncProductionSettingsPage)}
									/>
									<Redirect to="/" />
								</Switch>
								<Footer />
							</div>
						</div>
					)}
				/>
			</div>
		);
	}
}

export default App;
