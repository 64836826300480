import { createReducer } from '../../app/common/util/reducerUtil';
import {
	FETCH_CLOCKED_IN_STATUS,
	FETCH_CLOCKED_HOURS
} from './dashboardConstants';

const initialState = {
	crew: {},
	clockedMinutes: {}
};

export const fetchClockedInStatus = (state, payload) => {
	return { ...state, crew: payload.crewStats };
};

export const fetchClockedHours = (state, payload) => {
	return { ...state, clockedMinutes: payload.hoursStats };
};

export default createReducer(initialState, {
	[FETCH_CLOCKED_IN_STATUS]: fetchClockedInStatus,
	[FETCH_CLOCKED_HOURS]: fetchClockedHours
});
