import React, { Component } from 'react';
import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../../ui/icons/';
import { firebaseEnv } from '../../../config/firebase';

const subMenuClickHandler = e => {
	e.preventDefault();
	const expanded = e.target.getAttribute('aria-expanded') === 'true' || false;
	e.target.setAttribute('aria-expanded', !expanded);
};

class NavigationItems extends Component {
	render() {
		const { isAdmin, visible, toggle } = this.props;
		const classes = ['main-nav'];
		if (visible) {
			classes.push('open');
		} else classes.push('closed');
		return (
			<nav className={classes.join(' ')}>
				{firebaseEnv === 'staging' && (
					<div className="staging-label">Staging</div>
				)}
				<ul className="navigation-items">
					<NavigationItem
						link="/dashboard"
						exact
						icon={icons.faTachometer}
						toggle={toggle}
					>
						Dashboard
					</NavigationItem>
					<li className="navigation-item">
						<span onClick={subMenuClickHandler} aria-expanded="false">
							<FontAwesomeIcon icon={icons.faClock} /> Schedule{' '}
							<FontAwesomeIcon icon={icons.faChevronDown} className="arrow" />
						</span>
						<ul className="sub-menu">
							<NavigationItem
								link="/clock-in"
								icon={icons.faSignInAlt}
								toggle={toggle}
							>
								Clock In
							</NavigationItem>
							<NavigationItem
								link="/clock-out"
								icon={icons.faSignOutAlt}
								toggle={toggle}
							>
								Clock Out
							</NavigationItem>
							<NavigationItem
								link="/batch-clock"
								icon={icons.faSync}
								toggle={toggle}
							>
								Batch Functions
							</NavigationItem>
						</ul>
					</li>
					<NavigationItem
						link="/employees"
						icon={icons.faAddressCard}
						toggle={toggle}
					>
						Employees
					</NavigationItem>
					<NavigationItem
						link="/departments"
						icon={icons.faSitemap}
						toggle={toggle}
					>
						Departments
					</NavigationItem>
					<NavigationItem
						link="/reports"
						icon={icons.faClipboard}
						toggle={toggle}
					>
						Reports
					</NavigationItem>
					<NavigationItem link="/help" icon={icons.faLifeRing} toggle={toggle}>
						Help
					</NavigationItem>
					{isAdmin && (
						<div>
							<div className="nav-split">Admin Functions</div>
							<NavigationItem
								link="/users"
								exact
								icon={icons.faUserEdit}
								toggle={toggle}
							>
								System Users
							</NavigationItem>
							<NavigationItem
								link="/companies"
								exact
								icon={icons.faBuilding}
								toggle={toggle}
							>
								Companies
							</NavigationItem>
							<NavigationItem
								link="/production-settings"
								exact
								icon={icons.faCog}
								toggle={toggle}
							>
								Production Settings
							</NavigationItem>
						</div>
					)}
				</ul>
			</nav>
		);
	}
}

export default NavigationItems;
