import React, { Component } from 'react';
import './footer.css';
import LicenceInfo from '../LicenceInfo';
import packageJson from '../../../../../package.json';

class Footer extends Component {
	render() {
		return (
			<footer className="no-print">
				<div className="sub-footer">
					<div className="powered-by">
						Powered by: <strong>CrewTracker</strong> | &copy;{' '}
						{new Date().getFullYear()}
					</div>
					<div className="ver">Version {packageJson.version}</div>
				</div>
				<LicenceInfo />
			</footer>
		);
	}
}

export default Footer;
