import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { reducer as toastrReducer } from 'react-redux-toastr';
import asyncReducer from '../../app/async/asyncReducer';
import dashboardReducer from '../../features/dashboard/dashboardReducer';

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	form: FormReducer,
	async: asyncReducer,
	toastr: toastrReducer,
	dashboard: dashboardReducer
});

export default rootReducer;
