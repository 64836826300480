import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import './login.css';
import {
	combineValidators,
	composeValidators,
	hasLengthGreaterThan,
	isRequired,
	createValidator
} from 'revalidate';
import AuthTextInput from '../../app/common/form/AuthTextInput';
import { login, resetPassword } from './authActions';
import packageJson from '../../../package.json';

const mapState = state => ({
	loading: state.async.loading,
	auth: state.firebase.auth,
	profile: state.firebase.profile,
	formStatus: state.form.loginForm
});
const actions = {
	login,
	resetPassword
};

const isValidEmail = createValidator(
	message => value => {
		if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return message;
		}
	},
	'Invalid email address'
);

const validate = combineValidators({
	email: composeValidators(
		isRequired({ message: 'Email Address is required' }),
		isValidEmail
	)(),
	password: composeValidators(
		isRequired({ message: 'Password is required' }),
		hasLengthGreaterThan(5)({
			message: 'Password needs to be 6 characters or longer'
		})
	)()
});

class LoginPage extends Component {
	state = {
		isLogin: true
	};
	handleLoginFormSwitch = () => {
		this.setState({ isLogin: !this.state.isLogin });
	};

	render() {
		const {
			login,
			resetPassword,
			handleSubmit,
			error,
			invalid,
			submitting,
			loading,
			pristine,
			formStatus
		} = this.props;
		const { isLogin } = this.state;
		if (
			this.props.auth.isLoaded &&
			!this.props.auth.isEmpty &&
			this.props.profile.isLoaded &&
			!this.props.profile.isEmpty
		) {
			return <Redirect to="/dashboard" />;
		}
		return (
			<div className="login-page-container">
				<div className="login-page">
					<div className="login-logo">
						<img
							src="/assets/images/crew-tracker-logo-login.svg"
							alt="Crew Tracker"
						/>
					</div>
					{isLogin ? (
						<div className="login-box">
							<h2>Login</h2>
							{error && <div className="loginErrorMessage">{error}</div>}
							<Form size="large" onSubmit={handleSubmit(login)}>
								<Field
									label="Your Email Address"
									name="email"
									component={AuthTextInput}
									type="text"
									placeholder="name@domain.com"
								/>
								<Field
									label="Your Password"
									name="password"
									component={AuthTextInput}
									type="password"
									placeholder="********"
								/>
								<Button
									loading={loading}
									disabled={invalid || submitting || pristine || loading}
									fluid
									size="large"
									className="button-dark-blue"
								>
									{this.props.auth.isLoaded && !this.props.auth.isEmpty
										? 'LOADING...'
										: 'LOGIN'}
								</Button>
							</Form>
						</div>
					) : (
						<div className="login-box single-input">
							<h2>Forgot password?</h2>
							{error && <div className="loginErrorMessage">{error}</div>}
							{formStatus && !formStatus.submitSucceeded ? (
								<Form size="large" onSubmit={handleSubmit(resetPassword)}>
									<Field
										label="Your Email Address"
										name="email"
										component={AuthTextInput}
										type="text"
										placeholder="name@domain.com"
									/>
									<Button
										loading={loading}
										disabled={invalid || submitting || pristine || loading}
										fluid
										size="large"
										className="button-dark-blue"
									>
										REQUEST NEW PASSWORD
									</Button>
								</Form>
							) : (
								<div className="loginSuccessMessage">
									The email has been sent with password reset instructions.
								</div>
							)}
						</div>
					)}
					<div className="login-box-footer">
						<p
							className="forgot-pass-link"
							onClick={this.handleLoginFormSwitch}
						>
							{isLogin ? 'Forgot Password?' : '← Back to Login'}
						</p>
						<p>
							&copy;{' '}
							<a
								href="https://bigbrave.digital"
								target="_blank"
								rel="noopener noreferrer"
							>
								CrewTracker
							</a>
							{' - '} All Rights Reserved
						</p>
						<div className="version login">{packageJson.version}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	mapState,
	actions
)(
	reduxForm({ form: 'loginForm', enableReinitialize: true, validate })(
		LoginPage
	)
);
