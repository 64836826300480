import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

//  CHECK IF USER IS LOGGED IN
export const UserIsAuthenticated = connectedRouterRedirect({
	// The url to redirect user to if they fail
	redirectPath: '/',
	allowRedirectBack: false,
	// If selector is true, wrapper will not redirect
	// For example let's check that state contains user data
	authenticatedSelector: (state) =>
		state.firebase.auth.isLoaded && !state.firebase.auth.isEmpty,
	// A nice display name for this check
	wrapperDisplayName: 'UserIsAuthenticated'
});

//  CHECK IF USER HAS ADMIN PRIVILEGES
export const VisibleOnlyAdmin = connectedAuthWrapper({
	authenticatedSelector: (state) =>
		state.firebase.auth.isLoaded &&
		!state.firebase.auth.isEmpty &&
		state.firebase.profile.isAdmin,
	wrapperDisplayName: 'VisibleOnlyAdmin'
});
